import React from "react"
import Layout from "../chunks/layout"
import { Helmet } from "react-helmet"
import Menu from "../components/menu"
import HeaderImage from "../../static/img/header-image.png"

export default function Contact({ location }) {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Contact | Boostik</title>
        <link rel="canonical" href="https://boostik.io/" />
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <div className={`background-gradient-home`} id="contact-page">
        <Menu />
        <div className="flex justify-center items-center p-20">
          <div className="header-section-content">
            <div className="header-section-title">Contact</div>
            <p className="header-section-description">
              Email us with any questions{" "}
              <a
                href="mailto:info@boostik.io"
                className="text-white text-bold"
              >
                info@boostik.io
              </a>
            </p>
            <p className="header-section-description">
              We would be happy to answer your question and set up a meeting
              with you.
            </p>
          </div>
          <div>
            <img
              style={{ width: "500px" }}
              src={HeaderImage}
              className="header-section-image"
              alt="mobile"
            />
          </div>
        </div>
        <div className="background-gray-wave-separator"></div>
      </div>
    </Layout>
  )
}
